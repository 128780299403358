<template>
    <ForgotPasswordForm />
</template>

<script>
import ForgotPasswordForm from '@/components/auth/ForgotPasswordForm'

export default ({
    components: {
        ForgotPasswordForm
    },
    setup() {
        
    },
})
</script>
